import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Form, Spinner, Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyInput, { convertToNumber } from '../../utils/CurrencyInput';
import { formatCurrency } from '../../utils';

const estados = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];

const statusOptions = [
  { value: 'pendente', label: 'Pendente' },
  { value: 'confirmado', label: 'Confirmado' },
  { value: 'cancelado', label: 'Cancelado' },
  { value: 'entregue', label: 'Entregue' },
];

const formasPagamento = [
  { value: 'boleto', label: 'Boleto' },
  { value: 'cartao_credito', label: 'Cartão de Crédito' },
  { value: 'pix', label: 'PIX' },
  { value: 'transferencia', label: 'Transferência Bancária' },
  { value: 'dinheiro', label: 'Dinheiro' },
];

const tipoOptions = [
  { value: 'entrada', label: 'Entrada' },
  { value: 'saida', label: 'Saída' },
];

const CompraForm: React.FC = () => {
  const [itens, setItens] = useState<
    {
      produto_id: string | number;
      quantidade: number;
      valor_unitario: string;
    }[]
  >([]);
  const [tipo, setTipo] = useState<string>('entrada');
  const [notaFiscal, setNotaFiscal] = useState<string>('');
  const [cnpjFornecedor, setCnpjFornecedor] = useState<string>('');
  const [nomeFornecedor, setNomeFornecedor] = useState<string>('');
  const [enderecoFornecedor, setEnderecoFornecedor] = useState<string>('');
  const [cidadeFornecedor, setCidadeFornecedor] = useState<string>('');
  const [estadoFornecedor, setEstadoFornecedor] = useState<string>('');
  const [cepFornecedor, setCepFornecedor] = useState<string>('');
  const [dataCompra, setDataCompra] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [prazoEntrega, setPrazoEntrega] = useState<string>('');
  const [formaPagamento, setFormaPagamento] = useState<string>('');
  const [observacoes, setObservacoes] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [produtos, setProdutos] = useState<
    { value: string | number; label: string }[]
  >([]);

  const [errors, setErrors] = useState<{
    tipo?: string;
    notaFiscal?: string;
    cnpjFornecedor?: string;
    nomeFornecedor?: string;
    enderecoFornecedor?: string;
    cidadeFornecedor?: string;
    estadoFornecedor?: string;
    cepFornecedor?: string;
    dataCompra?: string;
    status?: string;
    prazoEntrega?: string;
    formaPagamento?: string;
    itens?: string;
  }>({});

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('authToken');

  useEffect(() => {
    const fetchProdutos = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/produtos`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const produtoOptions = response.data.data.map((produto: any) => ({
          value: produto.id,
          label: `${produto.descricao} - ${produto.sku}`,
        }));
        setProdutos(produtoOptions);
      } catch (error) {
        toast.error('Erro ao carregar produtos.');
      }
    };

    fetchProdutos();

    if (id) {
      axios
        .get(`${import.meta.env.VITE_APP_API_URL}/compras/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const compra = response.data.data;
          setItens(
            compra.itens.map((item: any) => ({
              produto_id: item.produto_id,
              quantidade: item.quantidade,
              valor_unitario: formatCurrency(item.valor_unitario.toString()),
            }))
          );
          setTipo(compra.tipo || 'entrada');
          setNotaFiscal(compra.nota_fiscal || '');
          setCnpjFornecedor(compra.cnpj_fornecedor || '');
          setNomeFornecedor(compra.nome_fornecedor || '');
          setEnderecoFornecedor(compra.endereco_fornecedor || '');
          setCidadeFornecedor(compra.cidade_fornecedor || '');
          setEstadoFornecedor(compra.estado_fornecedor || '');
          setCepFornecedor(compra.cep_fornecedor || '');
          setDataCompra(compra.data_compra || '');
          setStatus(compra.status || '');
          setPrazoEntrega(compra.prazo_entrega || '');
          setFormaPagamento(compra.forma_pagamento || '');
          setObservacoes(compra.observacoes || '');

          setIsLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao carregar os dados da compra.');
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [id, token]);

  const handleAddItem = () => {
    setItens([...itens, { produto_id: '', quantidade: 1, valor_unitario: '' }]);
  };

  const handleRemoveItem = (index: number) => {
    const newItens = [...itens];
    newItens.splice(index, 1);
    setItens(newItens);
  };

  const handleItemChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const newItens = [...itens];
    newItens[index] = { ...newItens[index], [field]: value };
    setItens(newItens);
  };

  const handleSave = async () => {
    let hasError = false;
    const newErrors: typeof errors = {};

    // Validação dos campos obrigatórios
    if (!tipo) {
      newErrors.tipo = 'O campo Tipo é obrigatório.';
      hasError = true;
    }
    if (!notaFiscal) {
      newErrors.notaFiscal = 'O campo Nota Fiscal é obrigatório.';
      hasError = true;
    }
    if (!cnpjFornecedor) {
      newErrors.cnpjFornecedor = 'O campo CNPJ do Fornecedor é obrigatório.';
      hasError = true;
    }
    if (!nomeFornecedor) {
      newErrors.nomeFornecedor = 'O campo Nome do Fornecedor é obrigatório.';
      hasError = true;
    }
    if (!enderecoFornecedor) {
      newErrors.enderecoFornecedor =
        'O campo Endereço do Fornecedor é obrigatório.';
      hasError = true;
    }
    if (!cidadeFornecedor) {
      newErrors.cidadeFornecedor =
        'O campo Cidade do Fornecedor é obrigatório.';
      hasError = true;
    }
    if (!estadoFornecedor) {
      newErrors.estadoFornecedor =
        'O campo Estado do Fornecedor é obrigatório.';
      hasError = true;
    }
    if (!cepFornecedor) {
      newErrors.cepFornecedor = 'O campo CEP do Fornecedor é obrigatório.';
      hasError = true;
    }
    if (!dataCompra) {
      newErrors.dataCompra = 'O campo Data da Compra é obrigatório.';
      hasError = true;
    }
    if (!status) {
      newErrors.status = 'O campo Status é obrigatório.';
      hasError = true;
    }
    if (!prazoEntrega) {
      newErrors.prazoEntrega = 'O campo Prazo de Entrega é obrigatório.';
      hasError = true;
    }
    if (!formaPagamento) {
      newErrors.formaPagamento = 'O campo Forma de Pagamento é obrigatório.';
      hasError = true;
    }

    // Validação dos itens (pelo menos um item deve ser adicionado)
    if (itens.length === 0) {
      newErrors.itens = 'Adicione pelo menos um item.';
      hasError = true;
    } else {
      for (const item of itens) {
        if (!item.produto_id || !item.quantidade || !item.valor_unitario) {
          newErrors.itens =
            'Todos os campos dos itens são obrigatórios. E Quantidade e Valor Unitário devem ser maiores que zero.';
          hasError = true;
          break;
        }
      }
    }

    setErrors(newErrors);

    if (hasError) {
      toast.error('Por favor, corrija os erros no formulário.');
      return;
    }

    const compraData = {
      tipo,
      nota_fiscal: notaFiscal,
      cnpj_fornecedor: cnpjFornecedor,
      nome_fornecedor: nomeFornecedor,
      endereco_fornecedor: enderecoFornecedor,
      cidade_fornecedor: cidadeFornecedor,
      estado_fornecedor: estadoFornecedor,
      cep_fornecedor: cepFornecedor,
      data_compra: dataCompra,
      status,
      prazo_entrega: prazoEntrega,
      forma_pagamento: formaPagamento,
      observacoes,
      itens: itens.map((item) => ({
        produto_id: item.produto_id,
        quantidade: item.quantidade,
        valor_unitario: convertToNumber(item.valor_unitario),
      })),
    };
    console.log(compraData);

    try {
      if (id) {
        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/compras/${id}`,
          compraData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success('Compra atualizada com sucesso!');
      } else {
        await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/compras`,
          compraData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success('Compra criada com sucesso!');
      }
      navigate('/compras');
    } catch (err) {
      const error = err as any;
      console.error('Erro ao salvar a compra:', error);

      if (error.response && error.response.data) {
        console.error('Resposta do servidor:', error.response.data);
      }
      toast.error('Erro ao salvar a compra.');
    }
  };

  const handleCancel = () => {
    navigate('/compras');
  };

  if (isLoading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <Card className="shadow-sm">
        <Card.Header as="h4" className="text-center bg-primary text-white">
          {id ? 'Editar Compra' : 'Nova Compra'}
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Tipo</Form.Label>
                  <Select
                    options={tipoOptions}
                    value={tipoOptions.find((option) => option.value === tipo)}
                    onChange={(option) => setTipo(option?.value || 'entrada')}
                    isClearable
                    placeholder="Selecione o tipo"
                  />
                  {errors.tipo && (
                    <div className="text-danger">{errors.tipo}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {itens.map((item, index) => (
              <div key={index}>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <br />
                      <Form.Label>Produto</Form.Label>
                      <Select
                        options={produtos}
                        value={produtos.find(
                          (option) => option.value === item.produto_id
                        )}
                        onChange={(option) =>
                          handleItemChange(
                            index,
                            'produto_id',
                            option?.value || ''
                          )
                        }
                        isClearable
                        placeholder="Selecione um produto"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <br />
                      <Form.Label>Quantidade</Form.Label>
                      <Form.Control
                        type="number"
                        min="1"
                        value={item.quantidade}
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            'quantidade',
                            Number(e.target.value)
                          )
                        }
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <br />
                      <Form.Label>Valor Unitário</Form.Label>
                      <CurrencyInput
                        value={item.valor_unitario}
                        onChange={(newValue: string) =>
                          handleItemChange(index, 'valor_unitario', newValue)
                        }
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Button
                  variant="danger"
                  onClick={() => handleRemoveItem(index)}
                >
                  Remover Item
                </Button>
                <hr />
              </div>
            ))}
            {errors.itens && <div className="text-danger">{errors.itens}</div>}
            <br />
            <Button variant="secondary" onClick={handleAddItem}>
              Adicionar Item
            </Button>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <br />
                  <Form.Label>Nota Fiscal</Form.Label>
                  <Form.Control
                    type="text"
                    value={notaFiscal}
                    onChange={(e) => setNotaFiscal(e.target.value)}
                    isInvalid={!!errors.notaFiscal}
                  />
                  {errors.notaFiscal && (
                    <Form.Control.Feedback type="invalid">
                      {errors.notaFiscal}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <br />
                  <Form.Label>CNPJ do Fornecedor</Form.Label>
                  <Form.Control
                    type="text"
                    value={cnpjFornecedor}
                    onChange={(e) => setCnpjFornecedor(e.target.value)}
                    isInvalid={!!errors.cnpjFornecedor}
                  />
                  {errors.cnpjFornecedor && (
                    <Form.Control.Feedback type="invalid">
                      {errors.cnpjFornecedor}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Nome do Fornecedor</Form.Label>
                  <Form.Control
                    type="text"
                    value={nomeFornecedor}
                    onChange={(e) => setNomeFornecedor(e.target.value)}
                    isInvalid={!!errors.nomeFornecedor}
                  />
                  {errors.nomeFornecedor && (
                    <Form.Control.Feedback type="invalid">
                      {errors.nomeFornecedor}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Endereço do Fornecedor</Form.Label>
                  <Form.Control
                    type="text"
                    value={enderecoFornecedor}
                    onChange={(e) => setEnderecoFornecedor(e.target.value)}
                    isInvalid={!!errors.enderecoFornecedor}
                  />
                  {errors.enderecoFornecedor && (
                    <Form.Control.Feedback type="invalid">
                      {errors.enderecoFornecedor}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Cidade do Fornecedor</Form.Label>
                  <Form.Control
                    type="text"
                    value={cidadeFornecedor}
                    onChange={(e) => setCidadeFornecedor(e.target.value)}
                    isInvalid={!!errors.cidadeFornecedor}
                  />
                  {errors.cidadeFornecedor && (
                    <Form.Control.Feedback type="invalid">
                      {errors.cidadeFornecedor}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Estado do Fornecedor</Form.Label>
                  <Select
                    options={estados}
                    value={estados.find(
                      (option) => option.value === estadoFornecedor
                    )}
                    onChange={(option) =>
                      setEstadoFornecedor(option?.value || '')
                    }
                    isClearable
                    placeholder="Selecione um estado"
                  />
                  {errors.estadoFornecedor && (
                    <div className="text-danger">{errors.estadoFornecedor}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>CEP do Fornecedor</Form.Label>
                  <Form.Control
                    type="text"
                    value={cepFornecedor}
                    onChange={(e) => setCepFornecedor(e.target.value)}
                    isInvalid={!!errors.cepFornecedor}
                  />
                  {errors.cepFornecedor && (
                    <Form.Control.Feedback type="invalid">
                      {errors.cepFornecedor}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Data da Compra</Form.Label>
                  <Form.Control
                    type="date"
                    value={dataCompra}
                    onChange={(e) => setDataCompra(e.target.value)}
                    isInvalid={!!errors.dataCompra}
                  />
                  {errors.dataCompra && (
                    <Form.Control.Feedback type="invalid">
                      {errors.dataCompra}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Select
                    options={statusOptions}
                    value={statusOptions.find(
                      (option) => option.value === status
                    )}
                    onChange={(option) => setStatus(option?.value || '')}
                    isClearable
                    placeholder="Selecione o status"
                  />
                  {errors.status && (
                    <div className="text-danger">{errors.status}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Prazo de Entrega</Form.Label>
                  <Form.Control
                    type="date"
                    value={prazoEntrega}
                    onChange={(e) => setPrazoEntrega(e.target.value)}
                    isInvalid={!!errors.prazoEntrega}
                  />
                  {errors.prazoEntrega && (
                    <Form.Control.Feedback type="invalid">
                      {errors.prazoEntrega}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Forma de Pagamento</Form.Label>
                  <Select
                    options={formasPagamento}
                    value={formasPagamento.find(
                      (option) => option.value === formaPagamento
                    )}
                    onChange={(option) =>
                      setFormaPagamento(option?.value || '')
                    }
                    isClearable
                    placeholder="Selecione a forma de pagamento"
                  />
                  {errors.formaPagamento && (
                    <div className="text-danger">{errors.formaPagamento}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Observações</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={observacoes}
                className="no-border-radius"
                onChange={(e) => setObservacoes(e.target.value)}
              />
            </Form.Group>
            <div className="text-center mt-4">
              <Button variant="primary" onClick={handleSave} className="mr-2">
                Salvar
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CompraForm;
