import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  FaSignOutAlt,
  FaHome,
  FaNewspaper,
  FaCalculator,
  FaKey,
  FaBars,
} from 'react-icons/fa';
import { useAuth } from '../../../components/Private/AuthContext';
import ChangePasswordModal from './ChangePasswordModal';

// Estilos globais
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
`;

// Navbar estilizada
const Navbar = styled.nav`
  background-color: #2d2f33;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Brand = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;

  img {
    width: 120px;
    height: auto;
    margin-right: 10px;
  }
`;

const Hamburger = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

interface NavLinksProps {
  isOpen: boolean;
}

const NavLinks = styled.div<NavLinksProps>`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    background-color: #5bc0de;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #46a2c6;
    }

    &.warning {
      background-color: #f0ad4e;
    }

    &.primary {
      background-color: #0275d8;
    }

    &.danger {
      background-color: #d9534f;
    }
  }

  a:last-child {
    margin-right: 0;
  }

  .icon {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background-color: #343a40;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
`;

const AppNavbar: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('reloadCount');
    logout();
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <>
      <GlobalStyle />
      <Navbar>
        <Brand href="/">
          <img src="/logoheader.png" alt="Integrameli logo" />
        </Brand>
        <Hamburger onClick={toggleMenu} />
        <NavLinks isOpen={menuOpen}>
          <a href="/" style={{ backgroundColor: '#5bc0de' }}>
            <FaHome size={18} className="icon" /> Home
          </a>
          <a href="/calculadora" style={{ backgroundColor: '#5bc0de' }}>
            <FaCalculator size={18} className="icon" /> Calculadora
          </a>
          <a href="/noticias" className="warning">
            <FaNewspaper size={18} className="icon" /> Atualizações
          </a>
          {isAuthenticated && (
            <>
              <a onClick={() => setShowPasswordModal(true)} className="primary">
                <FaKey size={18} className="icon" /> Trocar Senha
              </a>
              <a onClick={handleLogout} className="danger">
                <FaSignOutAlt size={18} className="icon" /> Sair
              </a>
            </>
          )}
        </NavLinks>
      </Navbar>
      <ChangePasswordModal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
      />
    </>
  );
};

export default AppNavbar;
