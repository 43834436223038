import React, { useEffect } from 'react';
import AppNavbar from '../components/AppNavbar'; // Atualize o caminho conforme sua estrutura de pastas
import BuscaPreco from '../../../components/BuscaPreco/BuscaPreco';

const Busca: React.FC = () => {
  useEffect(() => {
    // Verifica o contador de recarregamentos na sessão
    let reloadCount = parseInt(sessionStorage.getItem('reloadCount') || '0', 10);

    if (reloadCount < 2) {
      // Incrementa o contador de recarregamentos
      reloadCount += 1;
      sessionStorage.setItem('reloadCount', reloadCount.toString());

      // Recarrega a página
      window.location.reload();
    }
  }, []);

  return (
    <>
      <AppNavbar />
      <BuscaPreco />
    </>
  );
};

export default Busca;
