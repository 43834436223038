import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/Private/AuthContext';
import { toast } from 'react-toastify';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import './login.css';

interface LoginResponse {
  token: string;
  user: {
    id: number;
    name: string;
    email: string;
    role: string;
    roleModulo: string;
    database: string;
  };
}

const Login: React.FC = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  useEffect(() => {
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.classList.remove('content');
    }
  }, []);

  useEffect(() => {
    // Verifica se o usuário está lembrado e preenche os campos
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    if (storedEmail && storedPassword) {
      setCredentials({ email: storedEmail, password: storedPassword });
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post<LoginResponse>(
        `${import.meta.env.VITE_APP_API_URL}/login`,
        credentials
      );

      const { token, user } = response.data;
      toast.success('Login realizado com sucesso!');

      sessionStorage.setItem('authToken', token);
      sessionStorage.setItem('userId', user.id.toString());
      sessionStorage.setItem('userEmail', user.email);
      sessionStorage.setItem('userName', user.name);
      sessionStorage.setItem('role', user.role);
      sessionStorage.setItem('database', user.database);

      login(token, user);

      if (rememberMe) {
        localStorage.setItem('email', credentials.email);
        localStorage.setItem('password', credentials.password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }
    } catch (error) {
      toast.error(
        'Falha no login. Verifique suas credenciais e tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPasswordSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setForgotPasswordLoading(true);

    try {
      await axios.post(`${import.meta.env.VITE_APP_API_URL}/forgot-password`, {
        email: forgotPasswordEmail,
      });
      toast.success(
        'Um link para redefinição de senha foi enviado para seu e-mail.'
      );
      setShowForgotPasswordModal(false);
    } catch (error) {
      toast.error('Ocorreu um erro. Por favor, tente novamente.');
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="text-center mb-4">Bem-vindo à</h2>
        <img src="/logoheader.png" alt="Integrameli Logo" className="logo" />
        <form onSubmit={handleLogin}>
          <div className="form-group mb-3 ">
            <label htmlFor="email">E-mail</label>
            <div className="input-group">
              <span className="input-group-text">
                <FaEnvelope />
              </span>
              <input
                type="email"
                className="form-control no-border-radius"
                id="email"
                placeholder="Digite seu e-mail aqui"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Senha</label>
            <div className="input-group">
              <span className="input-group-text">
                <FaLock />
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="password"
                placeholder="Digite sua senha"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="btn btn-outline-secondary no-border-radius"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label className="form-check-label" htmlFor="rememberMe">
                Lembrar-me
              </label>
            </div>
            <Button
              variant="link"
              className="text-primary p-0"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Esqueceu a senha?
            </Button>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block w-100"
            disabled={loading}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              'ENTRAR'
            )}
          </button>
        </form>
      </div>

      <Modal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Esqueceu sua senha?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Digite seu e-mail e enviaremos um link para redefinir sua senha.
          </p>
          <form onSubmit={handleForgotPasswordSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="forgotPasswordEmail">E-mail</label>
              <div className="input-group">
                <span className="input-group-text">
                  <FaEnvelope />
                </span>
                <input
                  type="email"
                  className="form-control"
                  id="forgotPasswordEmail"
                  placeholder="Digite seu e-mail"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <Button
              type="submit"
              className="btn btn-primary btn-block w-100"
              disabled={forgotPasswordLoading}
            >
              {forgotPasswordLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                'Enviar link de redefinição'
              )}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
