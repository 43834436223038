import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync,
  faChartPie,
  faInfoCircle,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';

// Animações e estilos
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h2`
  color: #343a40;
  text-align: center;
  margin-bottom: 20px;
  animation: ${slideIn} 0.5s ease-out;
`;

const ChartContainer = styled.div`
  height: 390px;
  position: relative;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2rem;
  color: #ffc107;
  animation: ${rotate} 1s linear infinite;
`;

const RefreshButton = styled.button`
  background-color: #ffc107;
  color: #343a40;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #e0a800;
  }
`;

const StyledAlert = styled(Alert)`
  animation: ${slideIn} 0.5s ease-out;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
  color: #17a2b8;
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #17a2b8;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

interface LegendItemProps {
  $active?: boolean;
}

const LegendItem = styled.div<LegendItemProps>`
  display: flex;
  align-items: center;
  margin: 5px 10px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.$active ? 0.7 : 1)};

  &:hover {
    opacity: 0.7;
  }
`;

const LegendColor = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 5px;
`;

const LegendLabel = styled.span`
  font-size: 0.9rem;
`;

const TopSalesContainer = styled.div`
  margin-top: 20px;
  animation: ${slideIn} 0.5s ease-out;
`;

const TopSaleItem = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const TopSaleIcon = styled(FontAwesomeIcon)`
  color: #ffc107;
  margin-right: 10px;
  font-size: 1.2rem;
`;

const TopSaleInfo = styled.div`
  flex-grow: 1;
`;

const TopSaleTitle = styled.h4`
  margin: 0;
  font-size: 1rem;
`;

const TopSaleAmount = styled.p`
  margin: 0;
  font-weight: bold;
  color: #28a745;
`;

// Componente principal
const EnhancedBiVendas: React.FC = () => {
  const [vendas, setVendas] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<'pie', number[], string> | null>(null);
  const [colors, setColors] = useState<string[]>([]);

  const fetchTopVendas = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/bi_vendasMax`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        }
      );

      if (Array.isArray(response.data.data)) {
        setVendas(response.data.data);
      } else {
        throw new Error('Formato de dados inválido');
      }
    } catch (error) {
      console.error('Erro ao buscar as 5 maiores vendas:', error);
      setError('Erro ao buscar as 5 maiores vendas.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopVendas();
  }, []);

  useEffect(() => {
    if (!loading && !error && vendas.length > 0) {
      // Gerar cores
      const generatedColors = vendas.map(
        () =>
          `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
            Math.random() * 255
          )}, ${Math.floor(Math.random() * 255)}, 0.6)`
      );
      setColors(generatedColors);

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          chartInstanceRef.current = new Chart(ctx, {
            type: 'pie',
            data: {
              labels: vendas.map(
                (venda) =>
                  `Venda ${venda.id_venda} Cidade: ${venda.city} `
              ),
              datasets: [
                {
                  label: 'Total Pago (BRL)',
                  data: vendas.map((venda) =>
                    parseFloat(venda.total_paid_amount)
                  ),
                  backgroundColor: generatedColors,
                  borderColor: 'rgba(255, 255, 255, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: '5 Maiores Vendas',
                  font: {
                    size: 18,
                    weight: 'bold',
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const venda = vendas[context.dataIndex];
                      const descricao =
                        venda.descricao || 'Pedido sem produto identificado';
                      return `${descricao}: R$ ${(
                        context.raw as number
                      ).toFixed(2)}`;
                    },
                  },
                },
                legend: {
                  display: false,
                },
              },
              animation: {
                duration: 1000,
                easing: 'easeOutQuart',
              },
            },
          });
        }
      }
    }
  }, [loading, error, vendas]);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  const handleLegendClick = (index: number) => {
    if (chartInstanceRef.current) {
      const newIndex = activeIndex === index ? null : index;
      setActiveIndex(newIndex);

      chartInstanceRef.current.setActiveElements(
        newIndex !== null ? [{ datasetIndex: 0, index: newIndex }] : []
      );
      chartInstanceRef.current.update();
    }
  };

  return (
    <Container>
      <Title>
        <FontAwesomeIcon icon={faChartPie} /> Análise das Maiores Vendas
      </Title>
      {loading ? (
        <LoadingSpinner>
          <FontAwesomeIcon icon={faSync} />
        </LoadingSpinner>
      ) : error ? (
        <StyledAlert variant="danger">{error}</StyledAlert>
      ) : (
        <>
          <ChartContainer>
            <canvas ref={chartRef}></canvas>
            <TooltipContainer>
              <InfoIcon
                icon={faInfoCircle}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
              {showTooltip && (
                <Tooltip>
                  Este gráfico mostra as 5 maiores vendas em termos de valor
                  total pago.
                </Tooltip>
              )}
            </TooltipContainer>
          </ChartContainer>
          <LegendContainer>
            {vendas.map((venda, index) => (
              <LegendItem
                key={venda.id_venda}
                onClick={() => handleLegendClick(index)}
                $active={activeIndex === index}
              >
                <LegendColor color={colors[index]} />
                <LegendLabel>{`Venda ${venda.city}`}</LegendLabel>
              </LegendItem>
            ))}
          </LegendContainer>
          <TopSalesContainer>
            {vendas.map((venda) => (
              <TopSaleItem key={venda.id_venda}>
                <TopSaleIcon icon={faTrophy} />
                <TopSaleInfo>
                  <TopSaleTitle>
                    {venda.descricao || `Venda ${venda.id_venda}`}
                  </TopSaleTitle>
                  <TopSaleAmount>
                    R$ {parseFloat(venda.total_paid_amount).toFixed(2)}
                  </TopSaleAmount>
                </TopSaleInfo>
              </TopSaleItem>
            ))}
          </TopSalesContainer>
        </>
      )}
      <RefreshButton onClick={fetchTopVendas}>
        <FontAwesomeIcon icon={faSync} /> Atualizar Dados
      </RefreshButton>
    </Container>
  );
};

export default EnhancedBiVendas;
